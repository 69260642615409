/* change the color of the text to white */
h1 {
    /* display: inline-block; */
    /* left: 0; */
    margin: 5px 1rem;
    color: white;
    font-family: 'Poppins', sans-serif;
}

h2 {
    display: inline-block;
    left: 0;
    margin: 5px 7rem;
}

.infoDisplay {
    display: flex;
    /* width: 1920px; */
    margin: auto;
}

/* .alignRight {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;
    height: 15vh;
    width: 100%;
    padding: 0 12rem;
    flex-wrap: nowrap;
} */

/* display: inline-block;
    left: 0;
    margin: 5px 7rem;
    font-family: Eina01, sans-serif; */
