/* column-width: 480px;
gutter-width: 20px; */

/* .app {
    margin: auto;
    font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
    font-weight: 300;
    text-align: center;
} */

.section {
    display: inline-block;
    max-width: 1920px;
    margin: auto;
    text-align: left;
    vertical-align: top;
    display: flex;
    /* justify-content: center;
    align-items: center; */
    padding: 25px;
    margin: 0 auto;
    flex-direction: row;
    flex-wrap: wrap;
    /* flex-flow: row wrap; */
    justify-content: center;
}

/* 1920px * 120% = 2304px; */
.player-wrapper {
    /* display: inline-block;
    max-width: 100%;
    margin: 0 50px;
    text-align: left;
    vertical-align: top; */
    margin: auto;
    max-width: 100%;
}

.react-player {
    margin-bottom: 10px;
    background: rgba(0, 0, 0, 0.1);
}

.faded {
    color: rgba(0, 0, 0, 0.5);
}

.footer {
    margin: 20px;
}

td {
    width: 20%;
}
table {
    width: 20%;
    margin: 30px;
}
tr {
    width: 20%;
}

.button {
    display: inline-block;
    padding: 08px 20px;
    font-size: 24px;
    cursor: pointer;
    text-align: center;
    text-decoration: none;
    outline: none;
    color: #111;
    background-color: #f9c439;
    border: none;
    border-radius: 15px;
    margin: 0 30px;
    font-family: 'Poppins', sans-serif;
    /* margin-top: 0rem; */
}

.button:hover {
    background-color: #f9c439;
}

.button:active {
    background-color: #f9c439;
    box-shadow: 0 5px #666;
    transform: translateY(4px);
}

/* style={{
    width: '100px',
    height: '50px',
    fontSize: '20px',
    backgroundColor: '#FB393F',
    color: 'white',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
}} */
