.submit-button {
    display: inline-block;
    padding: 16px 30px;
    font-size: 24px;
    cursor: pointer;
    text-align: center;
    text-decoration: none;
    outline: none;
    color: #fff;
    background-color: #fb393f;
    border: none;
    border-radius: 30px;
}

.submit-button:hover {
    background-color: #fb393f;
}

.submit-button:active {
    background-color: #fb393f;
    /* box-shadow: 0 5px #666; */
    transform: translateY(4px);
}

.form-submit {
    display: inline-block;
    padding: 20px;
}

form.form-submit {
    padding: 10px;
}

/* .form-div {
    position: absolute;
    right: 75px;
    top: 884px;
    width: 0px;
} */
