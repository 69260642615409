/* create css file for Header component, should be a solid color with a logo image on the left  */
.header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background-color: #fb393f;
    height: 6vh;
    width: 100%;
    padding: 0 5rem;
}

.logo {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 60px;
    /* width: 100px; */
    padding-left: 7em;
    /* animation: App-logo-spin infinite 20s linear; */
}

.title-header {
    display: flex;
    font-family: 'Poppins', sans-serif;
    font-size: 2rem;
    color: white;
    font-weight: 700;
    padding-left: 1rem;
}

/*  */
